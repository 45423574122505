<template>
  <div class="component-thrid-back" @click="backThrid">
    <van-icon name="revoke" size="20px" />
    <p>返回司机端</p>
  </div>
</template>
<script>
export default {
  methods: {
    backThrid() {
      uni.webView.reLaunch({
        url: "/pages/freight/my/index",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.component-thrid-back {
  position: fixed;
  bottom: 100px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60px;
  height: 60px;
  padding: 5px;
  // box-sizing: border-box;
  border-radius: 50%;
  background: #e1e1e1;
  p {
    margin-top: 5px;
    font-size: 10px;
  }
}
</style>